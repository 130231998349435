
const roundNumber = (number: number, n = 2) => {
   return Math.round(number * Math.pow(10, n)) / Math.pow(10, n);
}


export const getNetSellingPrice = (property_price: number, agency_fees: number, agency_fees_amount: number, fees_type: string) => {
   let result;
   if(fees_type === 'percent'){
      result  = roundNumber(property_price / (1 + (agency_fees / 100)))
   }else if(fees_type === 'euro'){
      result = roundNumber(property_price - agency_fees_amount);
   }else{
      result = 0;
   }
   return Math.max(0, result);
}

export const getPropertyPrice = (net_selling_price: number, agency_fees: number, agency_fees_amount: number, fees_type: string) => {
   let result;
   if(fees_type === 'percent'){
      result  =  roundNumber(net_selling_price + net_selling_price * (agency_fees / 100));
   }else if(fees_type === 'euro'){
      result = roundNumber(net_selling_price + agency_fees_amount);
   }else{
      result = 0;
   }
   return result;
}

export const getAgencyFees = (property_price: number, net_selling_price: number) => {
   const agency_fees = roundNumber(((property_price / net_selling_price) - 1) * 100, 3) || 0;
   const  agency_fees_amount = roundNumber(property_price  - net_selling_price) || 0;
   return {
      agency_fees,
      agency_fees_amount
   }
}
