import { RentEstimationRequest } from '@/interfaces/RentEstimations';
import { defineStore } from 'pinia';
import { Coordinates } from '@/interfaces/Coordinates';
import CountryMetadata from '@/interfaces/CountryMetadata';

export const useArea = defineStore('Area', {
  state() {
    return {
      country_metadata: {} as CountryMetadata,
    };
  },
  getters: {},
  actions: {
    getRentEstimations(data: RentEstimationRequest) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post('/api/rent-estimation', { ...data })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getTargetNetYield(params: Coordinates, number_of_rooms = 1) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post('/api/areas/metadata', {
            ...params,
            attributes: {
              first_offer_delay: {},
              area_rent_cap: {
                number_of_rooms: number_of_rooms,
                furnished: true,
                construction_period: '[1990,3000]',
              },
              target_net_yield: {},
            },
          })
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getInvestors(params: Coordinates) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post('api/motivated_investors', { coordinates: params })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCountryMetadata(country_code = 'FR') {
      if (Object.keys(this.country_metadata).length) return true;
      return new Promise((resolve, reject) => {
        this.$axios
          .get('api/roots/countries/metadata', { params: { country_code } })
          .then((response) => {
            response.data ? (this.country_metadata = response.data) : '';
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
