<template>
  <div :class="wrapClass">
    <ion-label v-if="label" position="stacked" color="primary" class="text-sm">{{ label }}</ion-label>
    <ion-item lines="none" :class="{ 'ion-invalid': errors.length }">
      <ion-input ref="input" :type="!hide ? 'text' : 'password'" v-bind="$attrs" :clea-on-edit="false"></ion-input>
      <ion-checkbox v-model="hide"></ion-checkbox>
      <div v-if="errors.length">
        <ion-note v-for="error in errors" :key="error" slot="error">
          {{ error }}
        </ion-note>
      </div>
    </ion-item>
  </div>
</template>

<script>
import { IonInput, IonItem, IonCheckbox, IonNote, IonLabel } from '@ionic/vue';
import { ref, watch, nextTick } from 'vue';
export default {
  name: 'BasePasswordInput',
  components: { IonInput, IonItem, IonCheckbox, IonNote, IonLabel },
  inheritAttrs: false,
  props: {
    class: {
      type: String,
      default: '',
    },
    wrapClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const hide = ref(true);
    const input = ref(null);

    watch(hide, () => {
      nextTick(() => {
        input.value.$el.setFocus();
      });
    });

    return {
      hide,
      input,
    };
  },
};
</script>

<style scoped>
ion-item {
  --highlight-height: 0;
  --inner-border-width: 0;
  --border-width: 1px;
}

ion-item::part(native) {
  border-radius: 30px;
  border-width: 1px;
  border-color: var(--ion-color-primary-border);
  height: 51px;
}

.ion-invalid::part(native) {
  border-color: var(--ion-color-danger) !important;
}

.ion-valid::part(native) {
  border-color: var(--ion-color-success) !important;
}

ion-input {
  --color: var(--ion-color-primary);
  --placeholder-color: var(--ion-color-monochome-dark-init);
  --placeholder-font-weight: 300;
}

ion-note {
  --color: transparent;
}

ion-checkbox {
  --size: 24px;
  --checkbox-background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EEye%3C/title%3E%3Ccircle cx='256' cy='256' r='64' fill='%23113749'/%3E%3Cpath d='M490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96c-42.52 0-84.33 12.15-124.27 36.11-40.73 24.43-77.63 60.12-109.68 106.07a31.92 31.92 0 00-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416c46.71 0 93.81-14.43 136.2-41.72 38.46-24.77 72.72-59.66 99.08-100.92a32.2 32.2 0 00-.1-34.76zM256 352a96 96 0 1196-96 96.11 96.11 0 01-96 96z'/%3E%3C/svg%3E");
  --checkbox-background-checked: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EEye Off%3C/title%3E%3Cpath d='M432 448a15.92 15.92 0 01-11.31-4.69l-352-352a16 16 0 0122.62-22.62l352 352A16 16 0 01432 448zM248 315.85l-51.79-51.79a2 2 0 00-3.39 1.69 64.11 64.11 0 0053.49 53.49 2 2 0 001.69-3.39zM264 196.15L315.87 248a2 2 0 003.4-1.69 64.13 64.13 0 00-53.55-53.55 2 2 0 00-1.72 3.39z'/%3E%3Cpath d='M491 273.36a32.2 32.2 0 00-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 00-71.82 11.79 4 4 0 00-1.56 6.63l47.24 47.24a4 4 0 003.82 1.05 96 96 0 01116 116 4 4 0 001.05 3.81l67.95 68a4 4 0 005.4.24 343.81 343.81 0 0067.24-77.4zM256 352a96 96 0 01-93.3-118.63 4 4 0 00-1.05-3.81l-66.84-66.87a4 4 0 00-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 00-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0072.64-11.55 4 4 0 001.61-6.64l-47.47-47.46a4 4 0 00-3.81-1.05A96 96 0 01256 352z'/%3E%3C/svg%3E");
  --border-width: unset;
  --border-style: unset;
  --border-color: transparent;
  --border-color-checked: transparent;
  --border-radius: unset;
  --checkmark-color: unset;
  --checkmark-width: unset;
  margin-top: 0;
  margin-bottom: 0;
}
</style>
