<template>
  <transition
    enter-active-class="transition duration-150"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-300 transition"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    mode="out-in">
    <slot />
  </transition>
</template>
