import { Native } from '@/interfaces/Native';
import { defineStore } from 'pinia';

// variable naming with underscore
export const useNativeStore = defineStore('Native', {
  state: (): Native => {
    return {
      data: {
        keyboard_is_open: false,
        is_desktop: false,
      },  
    };
  }, 
  getters: {
    getKeyboardState(state) {
      return state.data.keyboard_is_open;
    },
    is_desktop: (state) => state.data.is_desktop,
  },
});
