import { CityStore } from '@/interfaces/Cities';
import { defineStore } from 'pinia';
import flatmap from 'lodash.flatmap';

// variable naming with underscore
export const useCities = defineStore('Cities', {
  state: (): CityStore => {
    return {
      countries: {
        France: [],
      },
      cities: []
    };
  },
  getters: {
    getCityList(state) {
      let cityList = state.countries.France.filter((city) => city.metadata?.total_sold_properties);
      cityList = cityList.map((city) => {
        if (city.name.includes('Paris')) {
          city.name = 'à  Paris';
          return city;
        }
        const prefix = city.name.substring(0, city.name.indexOf(' '));
        const cityName = city.name.substring(city.name.indexOf(' ') + 1);
        prefix.toLowerCase() === 'le' ? (city.name = 'au ' + cityName) : (city.name = 'à ' + cityName);
        return city;
      });
      const template = [
        'à Grand Paris',
        'à Marseille',
        'à Lille', 
        'à Avignon',
        'à Nîmes',
        'à Perpignan',
        'à Rouen',
        'au Havre',
      ];
      function sortFunc(a: any, b: any) {
        return template.indexOf(a.name) - template.indexOf(b.name);
      }
      cityList.sort(sortFunc);
      return cityList;
    },
    getCityMetadata: (state) => {
      return (id: any) => {
        if(Object.keys(state.cities).length) return state.cities[id].metadata
        else return false
      }
    }
  },
  actions: {
    generateCities() {
      const arr = this.countries.France;
      if(!arr.length) return false;
      const transformedPayload = flatmap(arr, ({ name, cities, metadata }) => {
        return cities.map((city) => {
          return {
            [city.id]: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              name,
              metadata,
              ...city
            }
          }
        })
      }) 
      this.cities = Object.assign({}, ...transformedPayload);
    },
    getCities() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get('/api/roots/city-groups')
          .then((result) => {
            this.countries = result.data;
            this.generateCities();
            resolve(true);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
