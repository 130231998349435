<template>
  <ion-header :class="{ desktop: is_desktop }" :collapse="is_desktop ? 'fade' : ''" mode="ios">
    <ion-toolbar mode="ios">
      <ion-buttons v-if="!is_desktop" slot="start">
        <ion-nav-link ref="back" router-direction="back">
          <ion-button color="tertiary">
            <ion-icon :icon="chevronBack" />
          </ion-button>
        </ion-nav-link>
      </ion-buttons>
      <ion-title class="text-base font-semibold text-monochrome-black">Profil</ion-title>
      <ion-buttons v-if="is_desktop" slot="end">
        <ion-menu-toggle>
          <ion-button fill="outline" color="tertiary" size="large" class="m-0 !h-[50px] w-[50px]">
            <ion-icon slot="icon-only" color="tertiary" size="large" class="h-[26px] w-[26px]" :icon="closeOutline" />
          </ion-button>
        </ion-menu-toggle>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div
      class="mx-auto flex min-h-full w-full flex-col pb-11"
      :class="is_desktop ? 'px-8 pt-9' : 'max-w-[374px] px-3 pt-[52px]'">
      <form @submit.prevent="submitHandler">
        <ion-item :class="{ 'ion-invalid': errors.first_name?.length }">
          <ion-label class="w-[117px] pr-5">Prénom</ion-label>
          <ion-input
            v-model="form_data.first_name"
            placeholder="Votre prénom"
            @ion-input="is_changed = true"></ion-input>
          <ion-note v-for="err in errors.first_name" slot="error" :key="err">
            {{ err }}
          </ion-note>
        </ion-item>
        <ion-item class="mt-[25px]" :class="{ 'ion-invalid': errors.last_name?.length }">
          <ion-label class="w-[117px] pr-5">Nom</ion-label>
          <ion-input v-model="form_data.last_name" placeholder="Votre nom" @ion-input="is_changed = true"></ion-input>
          <ion-note v-for="err in errors.last_name" slot="error" :key="err">
            {{ err }}
          </ion-note>
        </ion-item>
        <base-phone-input
          v-model="form_data.phone"
          placeholder="Votre téléphone"
          label="Téléphone"
          wrap-class="mt-[25px]"
          :errors="errors.phone"
          :simple="true"
          @ion-input="is_changed = true" />
        <ion-item :class="{ 'ion-invalid': errors.email?.length }">
          <ion-label class="w-[117px] pr-5">E-mail</ion-label>
          <ion-input v-model="form_data.email" placeholder="Votre e-mail" @ion-input="is_changed = true"></ion-input>
          <ion-note v-for="err in errors.email" slot="error" :key="err">
            {{ err }}
          </ion-note>
        </ion-item>
      </form>
      <div class="pt-12 text-center">
        <ion-button
          size="large"
          expand="block"
          color="tertiary"
          class="m-0"
          :disabled="!is_changed"
          @click="submitHandler()">
          Valider
        </ion-button>
        <ion-nav-link v-if="is_desktop" ref="back" router-direction="back" class="mt-6 inline-block cursor-pointer">
          <base-link-button color="tertiary" class="text-sm font-semibold">Annuler</base-link-button>
        </ion-nav-link>
      </div>
    </div>
  </ion-content>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButtons,
  IonButton,
  IonLabel,
  IonInput,
  IonNote,
  IonItem,
  IonNavLink,
  IonTitle,
  IonMenuToggle,
  toastController,
} from '@ionic/vue';
import { chevronBack, closeOutline } from '@/assets/icons/Icons';
import { useLoginStore } from '@/stores/auth/login';
import { useNativeStore } from '@/stores/native';
import BasePhoneInput from '@/components/UI/BasePhoneInput.vue';
import BaseLinkButton from '../UI/BaseLinkButton.vue';

const store = useLoginStore();
const nativeStore = useNativeStore();
const form_data = ref({
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
});
const errors = ref({
  first_name: [],
  last_name: [],
  phone: [],
});
const toast_message = ref('');
const is_changed = ref(false);
const back = ref(null);
const is_desktop = computed(() => {
  return nativeStore.data.is_desktop;
});

watch(
  () => store.user.id,
  (next) => {
    if (!next) return false;
    form_data.value.first_name = store.user.first_name;
    form_data.value.last_name = store.user.last_name;
    form_data.value.phone = store.user.phone;
    form_data.value.email = store.user.email;
  },
  { immediate: true }
);

const presentToast = async () => {
  const toast = await toastController.create({
    message: toast_message.value,
    duration: 6000,
    position: 'top',
    mode: 'ios',
    cssClass: is_desktop.value ? 'sidebar-toast m-0' : '',
    buttons: [
      {
        text: 'Fermer',
        role: 'cancel',
      },
    ],
  });
  await toast.present();
  toast_message.value = '';
};

const submitHandler = () => {
  store
    .updateUser({ ...form_data.value, phone_country_code: 'France' })
    .then(() => {
      errors.value = {
        first_name: [],
        last_name: [],
        phone: [],
      };
      back.value.$el.click();
      toast_message.value = 'Modifications enregistrées !';
      presentToast();
    })
    .catch(async (e) => {
      errors.value = {
        first_name: [],
        last_name: [],
        phone: [],
      };
      e.response.data?.errors ? (errors.value = e.response.data.errors) : '';
    });
};
</script>

<style scoped>
ion-item {
  --padding-start: 0;
}

ion-header.desktop:after {
  display: none;
}

ion-header.desktop ion-toolbar {
  --padding-start: 20px;
  --padding-end: 18px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-input {
  --color: var(--ion-color-primary);
  --placeholder-color: var(--ion-color-monochome-dark-init);
}
</style>
