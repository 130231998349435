import {Capacitor} from "@capacitor/core";
import { Haptics, ImpactStyle } from '@capacitor/haptics';


export const impact = (style = '') => {
   return new Promise<void>( (resolve) => {
      if(!Capacitor.isNativePlatform()) resolve();
      else{
         Haptics.impact({ style: style === 'medium' ? ImpactStyle.Medium : style === 'heavy' ? ImpactStyle.Heavy : ImpactStyle.Light })
         .then(() => {
            resolve();
         })
      }
   });
} 