import { defineStore } from 'pinia';
import { BeanstockFees } from '@/interfaces/BeanstockFees'
import { BeanstockFeesVersionParams } from '@/interfaces/YieldCalculator';

export const useBeanstockFees = defineStore('BeanstockFees', {
   state: () => {
      return {
         beanstock_fees: {} as BeanstockFees
      }
   },
   getters: { 
      getBeanstockFee: (state) => {
         return (version: number) => {
            let result: BeanstockFeesVersionParams;
            if (version && state.beanstock_fees.versions[version]) {
               result = {
                  percentage: state.beanstock_fees.versions[version].percentage,
                  minimumFees: state.beanstock_fees.versions[version].minimum_fees,
                  serviceFees: state.beanstock_fees.versions[version].service_fees
               }
            } else if (Object.keys(state.beanstock_fees.data).length) {
               result = {
                  percentage: state.beanstock_fees.data.percentage,
                  minimumFees: state.beanstock_fees.data.minimum_fees,
                  serviceFees: state.beanstock_fees.data.service_fees
               }
            } else {
               result = {
                  percentage: 7.5,
                  minimumFees: 10000,
                  serviceFees: 500
               }
            }
            return result;
         }
      }
   },
   actions: {
      getBeanstockFees() {
         this.$axios
           .get('api/settings?groups[]=fees')
           .then((response) => {
             if (response.data?.data?.fees?.beanstock_fees?.data) {
               this.beanstock_fees = response.data.data.fees.beanstock_fees;
             }
           })
           .catch((err) => console.log(err));
       }
   }
})

