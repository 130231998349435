import { defineStore } from 'pinia';
import { AnalysisForm } from '@/interfaces/PreAnalysesFormInput';
import { PreAnalysisData } from '@/interfaces/PreAnalysesFormInput';
import { YieldCalculatorRequest } from '@/interfaces/YieldCalculator';
import {
  YieldCalculator as Calculator,
  BeanstockFeesCalculator,
  NotaryFeesCalculator,
} from '@beanstock-codes/beanstock-calculators';
import { useArea } from '@/stores/area/index';
import { useLoginStore } from '@/stores/auth/login';
import { getNetSellingPrice } from '@/composables/propertyPrice';
import { PropertyPreanalysis } from '@/interfaces/PropertyPreanalysis';
import { useBeanstockFees } from '@/stores/pre-analyses/beanstockFees';

export const usePreAnalysisForm = defineStore('PreAnalysisForm', {
  state: (): AnalysisForm => {
    return {
      data: {
        address: '',
        street: '',
        city: '',
        country: '',
        country_code: '',
        postal_code: '',
        lat: 0,
        lng: 0,

        property_ownership_type: '',
        usage_type: 'residential_flat',

        surface_area: 0,
        flat_floor_area: 0,
        number_of_rooms: 0,
        epc_category: 'A',
        construction_period: '',
        outdoor_area: 0,
        average_area: 0,
        storey: 0,
        ground_floor_type: '',

        occupancy: 'notRented',

        repairs_type: null,
        repair_control_option: 'withoutRepairs',

        monthly_rent: 0,
        suggested_rent: 0,
        potential_rent: 0,

        fees_type: 'euro',
        agency_fees: 0,
        agency_fees_amount: 0,
        property_price: 0,
        net_selling_price: 0,

        hoa_fees: 0,
        hoa_fees_period: 'month',
        tax: 0,
        tax_period: 'year',

        elevator: 'no',
        mezzanine: 'no',
        balcony: 'no',
        garden: 'no',
        storage_room: 'no',
        parking: 'no',
        terrace: 'no',

        simulation_reason: '',
        mandate_type: '',
        agency_fees_charge: 'seller',
        additional_infos: '',
        acquisition_channel: 'inbound_beanstock_pro',
        renovation: 'with_repairs',
        calculator_settings: {
          beanstock_fees_version: 0,
        },
        images: [],
      },
      results: {
        total_budget: 0,
        net_yield: 0,
        monthly_net_rent: 0,
        repairs_and_furniture_cost: 0,
        agency_price: 0,
        beanstock_fees: 0,
        notary_fees: 0,
      },
      meta: {
        estimation: true,
        target_net_yield: 0,
        first_offer_delay: 0,
        simulation_id: '',
        is_open: false,
        to_last: false,
        is_modified: false,
        motivated_investors: 0,
        loading: false,
      },
      options: {
        floors: [
          {
            label: 'Rez-de-chaussée',
            value: 0,
          },
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5',
            value: 5,
          },
          {
            label: '6',
            value: 6,
          },
          {
            label: '7',
            value: 7,
          },
          {
            label: '8',
            value: 8,
          },
          {
            label: '9',
            value: 9,
          },
          {
            label: '10',
            value: 10,
          },
        ],
        ground_floor_type: [
          {
            label: 'Rez-de-chaussé sur rue',
            value: 'street_side',
          },
          {
            label: 'Rez-de-chaussé sur cour / jardin collectif',
            value: 'collective_courtyard_garden_side',
          },
          {
            label: 'Rez-de-chaussé sur cour / jardin privatif',
            value: 'private_courtyard_garden_side',
          },
        ],
        renovation: [
          {
            label: 'Après rénovation',
            value: 'with_repairs',
          },
          {
            label: 'En l’état',
            value: 'without_repairs',
          },
        ],
        property_ownership_types: [
          {
            label: `Copropriété \nAppartement à usage d'habitation`,
            value: 'condominium',
          },
          {
            label: 'Monopropriété \nMaison de ville',
            value: 'sole_ownership',
          },
        ],
        construction_period: [
          {
            label: 'Avant 1946',
            value: '[1,1946]',
          },
          {
            label: '1946-1970',
            value: '[1946,1970]',
          },
          {
            label: '1971-1990',
            value: '[1971,1990]',
          },
          {
            label: 'Après 1990',
            value: '[1990,3000]',
          },
          {
            label: 'Période inconnue',
            value: 'unknown',
          },
        ],
        epc: [
          {
            label: 'A',
            value: 'A',
          },
          {
            label: 'B',
            value: 'B',
          },
          {
            label: 'C',
            value: 'C',
          },
          {
            label: 'D',
            value: 'D',
          },
          {
            label: 'E',
            value: 'E',
          },
          {
            label: 'F',
            value: 'F',
          },
          {
            label: 'G',
            value: 'G',
          },
          {
            label: 'Inconnu - Bâtiment ancien',
            value: 'NpOldBuilding',
          },
          {
            label: 'Inconnu - Bâtiment récent',
            value: 'NpRecentBuilding',
          },
        ],
        fees_type: [
          {
            label: '%',
            value: 'percent',
          },
          {
            label: '€',
            value: 'euro',
          },
        ],
        mandate_type: [
          {
            label: 'Simple',
            value: 'simple',
          },
          {
            label: 'Exclusif',
            value: 'exclusive',
          },
          {
            label: 'Semi-exclusif',
            value: 'semi_exclusive',
          },
        ],
        occupancy: [
          {
            label: 'Libre',
            value: 'notRented',
          },
          {
            label: 'Loué (bail meublé)',
            value: 'rentedFurnished',
          },
          {
            label: 'Loué (bail non meublé)',
            value: 'rentedUnfurnished',
          },
        ],
        period: [
          {
            label: '/ mois',
            value: 'month',
          },
          {
            label: '/ trimestre',
            value: 'quarter',
          },
          {
            label: '/ an',
            value: 'year',
          },
        ],
        with_repairs_options: [
          {
            label: 'Peinture et ameublement',
            value: 'paintRefurbishment',
          },
          {
            label: 'Rénovation légère',
            value: 'light',
          },
          {
            label: 'Rénovation moyenne',
            value: 'moderate',
          },
          {
            label: 'Rénovation lourde',
            value: 'major',
          },
        ],
        without_repairs_options: [
          {
            label: 'Aucuns travaux et ameublement',
            value: 'noneWithoutRefurbishment',
          },
          {
            label: 'Ameublement uniquement',
            value: 'none',
          },
        ],
        repair_control_option: [
          {
            label: 'Avec travaux',
            value: 'withRepairs',
          },
          {
            label: 'Sans travaux',
            value: 'withoutRepairs',
          },
        ],
        estate_agency_fees_payer: [
          {
            label: 'Vendeur',
            value: 'seller',
          },
          {
            label: 'Acquéreur',
            value: 'buyer',
          },
        ],
        simulation_reason: [
          {
            label: 'J’estime la valeur d’un bien',
            value: 'estimate',
          },
          {
            label: 'J’ai déjà le mandat',
            value: 'have_mandate',
          },
        ],
      },
    };
  },
  actions: {
    roundNumber(number: number, n = 2) {
      return Math.round(number * Math.pow(10, n)) / Math.pow(10, n);
    },
    submitStepForm(values: PreAnalysisData) {
      this.data = { ...this.data, ...values };
    },
    calculateDefaultValues() {
      if (this.data.agency_fees_amount && this.data.agency_fees) {
        this.data.fees_type = 'percent';
      } else if (this.data.agency_fees) {
        this.data.fees_type = 'percent';
      } else if (this.data.agency_fees_amount) {
        this.data.fees_type = 'euro';
      }

      this.data.net_selling_price = getNetSellingPrice(this.data.property_price, this.data.agency_fees, 0, 'percent');

      !this.data.potential_rent ? (this.data.potential_rent = this.data.monthly_rent) : '';
      this.data.agency_fees_amount ? (this.data.agency_fees_amount = Number(this.data.agency_fees_amount)) : '';

      this.calculateResults();
      this.calculateMetaData();
    },
    convertOccupancy(value: string) {
      if (value === 'notRented') return 'vacant';
      else if (value === 'rentedFurnished') return 'occupied_fumished';
      else return 'occupied_unfumished';
    },
    calculateMetaData() {
      const areaStore = useArea();
      areaStore
        .getTargetNetYield(
          {
            latitude: this.data.lat,
            longitude: this.data.lng,
          },
          this.data.number_of_rooms
        )
        .then((result: any) => {
          result.metadata?.target_net_yield ? (this.meta.target_net_yield = result.metadata.target_net_yield) : '';
          result.metadata?.first_offer_delay ? (this.meta.first_offer_delay = result.metadata.first_offer_delay) : '';
        });

      areaStore
        .getInvestors({
          latitude: this.data.lat,
          longitude: this.data.lng,
        })
        .then((result: any) => {
          this.meta.motivated_investors = result.motivated_investors;
        });
    },
    async getEstimatedRent() {
      this.meta.loading = true;
      const areaStore = useArea();

      const payload = {
        resource: {
          lat: this.data.lat,
          lng: this.data.lng,
          number_of_rooms: this.data.number_of_rooms,
          surface_area: this.data.surface_area,
          outdoor_area: this.data.outdoor_area,
          flat_floor_area: this.data.flat_floor_area,
          storey: this.data.storey,
          balcony: this.data.balcony,
          parking: this.data.parking,
          terrace: this.data.terrace,
          garden: this.data.garden,
          elevator: this.data.elevator,

          ground_floor_type: this.data.ground_floor_type,
          property_ownership_type: this.data.property_ownership_type,
          sole_ownership_building_type: this.data.property_ownership_type === 'sole_ownership' ? 'house' : '',
        },
        resource_type: 'property_preanalyses',
      };

      areaStore.getRentEstimations(payload).then((result: any) => {
        this.meta.loading = false;
        this.data.suggested_rent = result.value;
      });
    },
    async calculateResults() {
      const BeanstockFeesStore = useBeanstockFees();
      let rent_value;
      let repairs_type;
      if (!BeanstockFeesStore.beanstock_fees.versions) {
        await BeanstockFeesStore.getBeanstockFees();
      }
      const beanstock_fees = BeanstockFeesStore.getBeanstockFee(this.data.calculator_settings.beanstock_fees_version);

      if (this.data.occupancy != 'notRented') {
        rent_value =
          this.data.renovation === 'with_repairs' && this.data.potential_rent
            ? this.data.potential_rent
            : this.data.monthly_rent;
        repairs_type = this.data.renovation === 'with_repairs' ? this.data.repairs_type : 'noneWithoutRefurbishment';
      } else {
        rent_value = this.data.monthly_rent;
        repairs_type = this.data.repairs_type;
      }

      const data: YieldCalculatorRequest = {
        address: this.data.address,
        surfaceArea: this.data.surface_area,
        flatFloorArea: this.data.flat_floor_area,
        netSellerGain: this.data.property_price,
        feesType: this.data.fees_type,
        estateAgencyFees: this.data.agency_fees,
        estateAgencyFeesPrice: this.data.agency_fees_amount,
        estateAgencyFeesPaidBy: this.data.agency_fees_charge,
        rent: rent_value,
        utilities: this.data.hoa_fees,
        utilitiesPeriod: 'month',
        propertyTax: this.data.tax,
        propertyTaxPeriod: 'year',
        repair: repairs_type,
        occupancy: this.data.occupancy,
        postalCode: this.data.postal_code,
        repairControlOption: this.data.repair_control_option,
        EPC: this.data.epc_category,
        beanstockFeesVersionParams: beanstock_fees,
      };

      const agency_fees_amount = this.data.agency_fees_amount
        ? this.data.agency_fees_amount
        : (this.data.agency_fees / 100) * this.data.property_price;
      const estateAgencyFeesPriceValue = this.data.agency_fees_charge === 'buyer' ? agency_fees_amount : 0;
      const propertyPrice = this.data.property_price - estateAgencyFeesPriceValue;

      const calculator = new Calculator(data);

      let net_yield = calculator.calculateYield();

      //decrease net_yield by 0.25% if occupancy = rentedUnfurnished
      this.data.occupancy === 'rentedUnfurnished' ? (net_yield = net_yield - net_yield * 0.25) : '';

      const results = {
        total_budget: calculator.calculateTotalBudget(),
        net_yield: net_yield < 0 ? 0 : net_yield,
        monthly_net_rent: calculator.calculateMonthlyNetRent(),
        agency_price: calculator.estateAgencyFeesAndPrice().price,
        repairs_and_furniture_cost: calculator.calculateRepairCost(),
        beanstock_fees: BeanstockFeesCalculator.calculateBeanstockFees(this.data.property_price, beanstock_fees),
        notary_fees: NotaryFeesCalculator.calculateNotaryFees({ propertyPrice }),
      };
      this.results = results;
    },
    load(simulation: PropertyPreanalysis, open_simulation = true) {
      this.meta.simulation_id = simulation.id;
      this.meta.target_net_yield = simulation.target_net_yield;
      Object.assign(this.data, simulation);
      if (open_simulation) {
        this.meta.to_last = true;
        this.meta.is_open = true;
      }
    },
    getFormData() {
      const occupancy = this.convertOccupancy(this.data.occupancy);
      //save always net_yield value calculated as with_repairs is chosen
      if (this.data.occupancy != 'notRented' && this.data.renovation === 'without_repairs') {
        this.data.renovation = 'with_repairs';
      }

      this.calculateResults();

      //sole_ownership_building_type
      const sole_ownership_building_type = this.data.property_ownership_type === 'sole_ownership' ? 'house' : '';

      const user = useLoginStore();

      const data = {
        address: this.data.address,
        street: this.data.street,
        country: this.data.country,
        city: this.data.city,
        lat: this.data.lat,
        lng: this.data.lng,
        country_code: this.data.country_code,
        postal_code: this.data.postal_code,
        property_ownership_type: this.data.property_ownership_type,
        usage_type: this.data.usage_type,
        sole_ownership_building_type: sole_ownership_building_type,
        property_price: this.data.property_price,
        number_of_rooms: this.data.number_of_rooms,
        surface_area: this.data.surface_area,
        flat_floor_area: this.data.flat_floor_area,
        outdoor_area: this.data.outdoor_area,
        construction_period: this.data.construction_period,
        average_area: this.data.average_area,
        storey: this.data.storey,
        ground_floor_type: this.data.ground_floor_type,
        hoa_fees: this.data.hoa_fees || 0,
        tax: this.data.tax,
        occupancy: occupancy,
        monthly_rent: this.data.monthly_rent,
        suggested_rent: this.data.suggested_rent,
        potential_rent: this.data.potential_rent,
        epc_category: this.data.epc_category,
        repairs_type: this.data.repairs_type,
        mandate_type: this.data.mandate_type,
        agency_fees_charge: this.data.agency_fees_charge,
        agency_fees: this.data.fees_type === 'percent' ? this.data.agency_fees : null,
        agency_fees_amount: this.data.fees_type === 'euro' ? this.data.agency_fees_amount : '',
        net_yield: this.results.net_yield,
        target_net_yield: this.meta.target_net_yield,
        additional_infos: this.data.additional_infos,
        acquisition_channel: this.data.acquisition_channel,
        total_budget: this.results.total_budget,
        repairs_and_furniture_cost: this.results.repairs_and_furniture_cost,
        created_by: user.user.first_name + ' ' + user.user.last_name,
        elevator: this.data.elevator,
        mezzanine: this.data.mezzanine,
        balcony: this.data.balcony,
        garden: this.data.garden,
        storage_room: this.data.storage_room,
        parking: this.data.parking,
        terrace: this.data.terrace,
      };

      return data;
    },
    savePreanalysisResults(id: string) {
      const data = this.getFormData();

      if (id) {
        return new Promise((resolve, reject) => {
          this.$axios
            .put(`api/roots/property-preanalyses/${id}`, data)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
      return new Promise((resolve, reject) => {
        this.$axios
          .post('api/roots/property-preanalyses', data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    savePreanalesesImages(id: string, images: []) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(`/api/roots/property-preanalyses/images/${id}`, { images })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    removePreanalesesImages(id: string, images: []) {
      return new Promise((resolve, reject) => {
        this.$axios
          .delete(`/api/roots/property-preanalyses/images/${id}`, { data: { image_ids: images } })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
