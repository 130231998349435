import { AnalyticsBrowser } from '@segment/analytics-next';
import { useUserStore } from '@/stores/me';

export function useAnalytics() {
  const { data } = useUserStore();
  const user = data.user;
 
  const analytics = AnalyticsBrowser.load({
    writeKey: process.env.VUE_APP_SEGMENT_KEY,
  });

  function clickedAnalytics(type: string, payload?: any) {
    analytics.track('Clicked', {
      type,
      clickData: payload,
    });
  }

  return { user, analytics, clickedAnalytics };
}
