<template>
  <ion-modal
    class="bg-white"
    :is-open="false"
    :class="{ desktop: is_desktop }"
    :keyboard-close="true"
    :backdrop-dismiss="false"
    :keep-contents-mounted="false"
    :enter-animation="fadeUpEnterAnimation"
    :leave-animation="fadeUpLeaveAnimation(animation_delay)"
    data-cy="simulation-modal"
    @will-present="onWillPresent"
    @did-present="onDidPresent"
    @did-dismiss="onClose()">
    <ion-content class="ion-padding md:flex md:items-center" data-cy="simulation-modal">
      <ion-nav ref="nav" :animation="is_desktop ? navSlideAnimation : ''"></ion-nav>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import { IonContent, IonModal, IonNav, loadingController } from '@ionic/vue';
import { ref, computed, defineAsyncComponent, watch } from 'vue';
import { usePreAnalysisForm } from '@/stores/pre-analyses/form';
import { useNativeStore } from '@/stores/native';
import { hideKeyboard } from '@/composables/keyboard';
import { fadeUpEnterAnimation, fadeUpLeaveAnimation, navSlideAnimation } from '@/composables/animations';

const Step0 = defineAsyncComponent(() => import('./steps/Step0.vue'));
const Step11 = defineAsyncComponent(() => import('./steps/Step11.vue'));
const Step12 = defineAsyncComponent(() => import('./steps/Step12.vue'));

const nav = ref(null);
const preAnalyses = usePreAnalysisForm();
const nativeStore = useNativeStore();
const is_desktop = computed(() => {
  return nativeStore.data.is_desktop;
});

function onClose() {
  preAnalyses.$reset();
}

const onWillPresent = () => {
  hideKeyboard();
};

// for <nav root = finalStep> case.
// watch to_last store value if newVal is false and oldVale was true set Step0 index as 1 in navigation stack
watch(
  () => preAnalyses.meta.to_last,
  (newValue, oldValue) => {
    if (!newValue && oldValue) {
      nav.value.$el.insert(1, Step0, null, { direction: 'forward' });
    }
  }
);

const onDidPresent = async () => {
  if (preAnalyses.meta.to_last) {
    const loader = await loadingController.create({
      cssClass: `simulation-loading ${is_desktop.value ? 'desktop' : ''}`,
      animated: !is_desktop.value,
    });
    preAnalyses.calculateDefaultValues();
    loader.present().then(() => {
      nav.value.$el.push(Step11, null, { duration: 0 });
      nav.value.$el.push(Step12, null, { duration: 0 }).then(() => {
        loader.dismiss();
      });
    });
  } else {
    nav.value.$el.setRoot(Step0);
  }
};

const animation_delay = computed(() => {
  return nativeStore.data.keyboard_is_open ? 500 : 0;
});
</script>
<style scoped>
ion-modal.desktop {
  --height: calc(100% - 80px);
  --width: calc(100% - 80px);
  --border-radius: 16px;
}

ion-modal.desktop::part(backdrop) {
  background: var(--ion-color-primary-border);
  opacity: 1;
}
</style>
<style>
ion-loading.simulation-loading {
  --backdrop-opacity: 1;
}

ion-loading.simulation-loading ion-backdrop {
  background: white;
}

ion-loading.simulation-loading.desktop ion-backdrop {
  background: transparent;
}

ion-loading.simulation-loading.desktop {
  --height: calc(100% - 80px);
  --width: calc(100% - 80px);
  --max-height: calc(100% - 80px);
  --max-width: calc(100% - 80px);
}

ion-loading.simulation-loading.desktop .loading-wrapper {
  background: white;
  box-shadow: none;
  border-radius: 16px;
}
</style>
