<template>
   <div :class="wrapClass">
      <ion-segment v-bind="$attrs" mode="ios">
         <ion-segment-button v-for="option in options" :key="option.value" :value="option.value" >
            <ion-label>{{option.label}}</ion-label>
         </ion-segment-button>
      </ion-segment>
   </div>
</template>

<script>
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/vue';
export default {
   name: 'BaseSegment',
   components: { IonLabel, IonSegment, IonSegmentButton },
   inheritAttrs: false,
   props: {
      options: {
         type: Array,
         default: () => {
            return []
         }
      },
      class: {
         type: String,
         default: ''
      },
      wrapClass: {
         type: String,
         default: ''
      }
   }
}
</script>
