export const genderOptions = [
  { label: 'M', value: 'male' },
  { label: 'Mme', value: 'female' },
];

export const agencyTypeOptions = [
  { label: 'Franchisée', value: 'franchise' },
  { label: 'Intégrée', value: 'integrated' },
  { label: 'Indépendante', value: 'independent' },
  { label: 'Mandataire', value: 'representative' },
];
