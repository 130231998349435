import { defineStore } from 'pinia';
import { AuthUser } from '@/interfaces/AuthUser';
import { Agency } from '@/interfaces/Agency';
import { Me } from '@/interfaces/Me';
import { useAnalytics } from '@/composables/segment';

// variable naming with underscore
export const useUserStore = defineStore('User', {
  state: (): Me => {
    return {
      data: {
        user: {} as AuthUser,
        agency: {} as Agency,
        role: '',
      },
    };
  },
  getters: {
    userId: (state) => state.data.user?.id,
    advisorFullName: (state) =>
      (state.data.agency.advisor?.user?.first_name || '') + ' ' + (state.data.agency.advisor?.user?.last_name || ''),
    advisorImage: (state) => state.data.agency.advisor?.image?.urlLarge,
    advisorPhone: (state) => state.data.agency.advisor?.user?.phone,
    advisorMail: (state) => state.data.agency.advisor?.user?.email,
    advisorMeeting: (state) => state.data.agency.advisor?.meeting_link,
    companyName: (state) => state.data.agency.company?.company_name,
    referralShareLinkUrl: (state) => {
      if (state.data.user) {
        return `https://app.beanstock.com/referral?id=${state.data.user?.id}&referrerName=${state.data.user?.first_name}&realtor=true&utm_source=pro&utm_campaign=pro`;
      } else {
        return '';
      }
    },
  },
  actions: {
    fetchUserData() {
      return new Promise<void>((resolve, reject) => {
        this.$axios
          .get('/api/roots/me')
          .then((response) => {
            if (!response.data.data.agency || Object.keys(response.data.data.agency).length === 0)
              throw new Error('None realtor user');
            else {
              this.data = response.data.data;
              const { analytics } = useAnalytics();
              analytics.identify(this.data.user.id, {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                address: this.data.user.address,
                name: this.data.user.first_name + this.data.user.last_name,
                email: this.data.user.email,
                userId: this.data.user.id,
              });
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
