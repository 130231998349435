import { createAnimation, Animation } from '@ionic/vue';

export const routerFadeInOutAnimation = (baseEl: any, opts: any) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo('opacity', 0, 1)
    .duration(300)
    .delay(300);

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo('opacity', 1, 0)
    .afterAddClass('ion-page-hidden')
    .duration(300);

  const animation: Animation = createAnimation()
    .addElement([opts.enteringEl, opts.leavingEl])
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);

  return animation;
};

export const navSlideAnimation = (baseEl: any, opts: any) => {
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo('transform', `translateX(${opts.direction === 'back' ? '-' : ''}100px)`, 'translateX(0)')
    .fromTo('opacity', 0, 1)
    .easing('ease-out')
    .duration(200)
    .delay(200);

  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo('transform', 'translateX(0)', `translateX(${opts.direction === 'back' ? '' : '-'}100px)`)
    .fromTo('opacity', 0.7, 0)
    .easing('ease-out')
    .afterAddClass('ion-page-hidden')
    .duration(200);

  const animation: Animation = createAnimation()
    .addElement([opts.enteringEl, opts.leavingEl])
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);

  return animation;
};

export const fadeUpEnterAnimation = (baseEl: any) => {
  const root = baseEl.shadowRoot;
  const wrapperAnimation = createAnimation()
    .addElement(root.querySelector('.modal-wrapper'))
    .keyframes([
      { offset: 0, opacity: '0', transform: 'translateY(100%)' },
      { offset: 1, opacity: '0.99', transform: 'translateY(0)' },
    ]);
  return createAnimation().addElement(baseEl).easing('ease-out').duration(200).addAnimation([wrapperAnimation]);
};

export const fadeUpLeaveAnimation = (delay: 0 | number) => {
  return (baseEl: any) => {
    const root = baseEl.shadowRoot;
    const wrapperAnimation = createAnimation()
      .addElement(root.querySelector('.modal-wrapper'))
      .keyframes([
        { offset: 0, opacity: '0', transform: 'translateY(100%)' },
        { offset: 1, opacity: '0.99', transform: 'translateY(0)' },
      ]);
    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(200)
      .direction('reverse')
      .delay(delay)
      .addAnimation([wrapperAnimation]);
  };
};
