import { createRouter, createWebHistory } from '@ionic/vue-router';
import { Preferences } from '@capacitor/preferences';
import { RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';
import AccountRequestScreen from '../views/auth/RegistrationFlow/AccountRequestScreen.vue';
import CreatePassword from '@/views/auth/RegistrationFlow/CreatePassword.vue';
import { useAnalytics } from '../composables/segment';
import { useIndexStore } from '@/stores/index';
import LoginWithPassword from '@/views/auth/LoginWithPassword.vue';

function load(component: string) {
  return () => import(`@/views/${component}.vue`);
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/page/home',
    meta: { requiresAuth: true },
  },
  {
    path: '/account-request-success',
    name: 'AccountRequestScreen',
    component: AccountRequestScreen,
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: load('SignIn'),
  },
  {
    path: '/login-with-password',
    name: 'LoginWithPassword',
    component: LoginWithPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: CreatePassword,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: load('Profile'),
    meta: { requiresAuth: true },
  },
  {
    path: '/page',
    component: TabsPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home',
        name: 'HomePage',
        component: load('Home'),
      },
      {
        path: 'tools',
        name: 'Tools',
        component: load('Tools'),
      },
      {
        path: 'simulations/:id?',
        name: 'Simulations',
        component: load('Simulation'),
      },
      {
        path: 'transactions/:id?',
        name: 'Transactions',
        component: load('Transactions'),
      },
      {
        path: '/page/transactions/section-home',
        name: 'SectionHome',
        component: load('SectionHome'),
      },
      {
        path: 'market',
        name: 'Market',
        component: load('Market'),
      },
      {
        path: 'referral',
        name: 'Referral',
        component: load('Referral'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const storage = await Preferences.get({ key: 'token' });
  const token = storage.value;
  const { analytics } = useAnalytics();
  analytics.page();
  const indexStore = useIndexStore();
  if (to.meta.requiresAuth && !token) {
    if (to.params?.id) {
      indexStore.redirect_after_login = to.fullPath;
    }
    next({ name: 'SignIn' });
    return;
  } else if (token && !to.meta.requiresAuth) {
    next('/');
    return;
  } else {
    next();
  }
});

export default router;
