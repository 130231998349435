<template>
  <ion-button fill="clear" class="m-0 !h-auto rounded-none underline">
    <slot />
  </ion-button>
</template>

<script>
import { IonButton } from '@ionic/vue';
export default {
  name: 'BaseLinkButton',
  components: { IonButton },
};
</script>

<style scoped>
ion-button {
  --background: none;
  --background-activated: transparent;
  --background-activated-opacity: 0;
  --background-focused: transparent;
  --background-focused-opacity: 0;
  --background-hover: transparent;
  --background-hover-opacity: 0;
  --box-shadow: none;
  --padding-bottom: 0;
  --padding-top: 0;
  --padding-end: 10px;
  --padding-start: 10px;
  --border-radius: 0;
}
</style>
