<template>
  <ion-page>
    <ion-header v-if="!is_desktop" class="ion-no-border">
      <ion-toolbar color="actionable-clear-10">
        <div class="mx-auto w-11/12 max-w-md">
          <ion-buttons v-if="hasNav" slot="start">
            <ion-back-button default-href="" color="primary" text="" :icon="chevronBack" />
          </ion-buttons>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="h-full w-full">
      <ion-content :class="{ desktop: is_desktop }">
        <img
          v-if="is_desktop"
          src="@/assets/images/auth_background.jpg"
          alt="background"
          class="absolute left-0 bottom-0 w-full h-full object-cover" />
        <div class="flex max-h-full min-h-full w-full">
          <div
            class="relative mx-auto flex max-h-full min-h-full w-full px-6"
            :class="{
              'max-h-full w-[460px] max-w-[460px] items-center rounded-xl bg-white px-0': is_desktop,
            }">
            <div
              :class="
                is_desktop
                  ? 'my-2 max-h-full w-full overflow-auto px-16 py-3'
                  : 'flex min-h-full w-full flex-col items-center justify-between'
              ">
              <div v-if="hasNav && is_desktop" class="absolute top-6 left-8">
                <ion-back-button default-href="" color="tertiary" text="" :icon="chevronBack"></ion-back-button>
              </div>

              <div
                class="text-center items-center flex flex-col"
                :class="is_desktop && 'absolute top-16 left-0 right-0'">
                <ion-icon :icon="beanstockNew" color="primary" class="h-[85px] w-[200px]"></ion-icon>
              </div>

              <div class="w-full" :class="is_desktop ? 'mt-11' : 'py-10'">
                <slot />
              </div>

              <div
                class="flex flex-col w-full items-center justify-center text-center gap-1"
                :class="is_desktop ? 'absolute bottom-10 left-0 right-0' : ''">
                <ion-note v-if="isForgotPassword" color="primary" class="text-sm m-0 font-medium">
                  Vous n’avez pas reçu de lien ?
                </ion-note>
                <base-link
                  :label="isForgotPassword ? 'Contactez-nous' : `Besoin d'aide ?`"
                  @click="contactOpen = true" />
              </div>
            </div>
          </div>
        </div>

        <sheet-modal
          :is-open="contactOpen"
          :init-breakpoint="0.25"
          :breakpoint="[0, 0.25, 0.25]"
          @close="contactOpen = false">
          <div class="mt-8 flex w-full flex-col items-center px-3">
            <ion-note color="primary" class="text-center text-base font-light">
              Un problème pour vous connecter ? Vous souhaitez créer des accès BeanstockPro ?
            </ion-note>
            <ion-button class="mt-4 rounded-xl" color="tertiary" @click="openLink">Contactez nous !</ion-button>
          </div>
        </sheet-modal>
      </ion-content>
    </div>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonIcon,
  IonNote,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
} from '@ionic/vue';
import { beanstockNew, chevronBack } from '@/assets/icons/Icons';
import { ref, computed } from 'vue';
import BaseLink from '@/components/UI/BaseLink.vue';
import SheetModal from '@/components/SheetModal.vue';
import { useNativeStore } from '@/stores/native';

defineProps({
  hasNav: {
    type: Boolean,
    default: false,
  },
  isForgotPassword: {
    type: Boolean,
    default: false,
  },
});

const contactOpen = ref(false);
const nativeStore = useNativeStore();

const is_desktop = computed(() => {
  return nativeStore.data.is_desktop;
});

function openLink() {
  window.location.href = 'https://beanstock.com/fr/partners';
}
</script>

<style scoped>
ion-content {
  --padding-bottom: 40px;
  --padding-top: 0;
  --background: var(--ion-color-actionable-clear);
}
ion-content.desktop {
  --padding-top: 40px;
}
</style>
