<template>
  <ion-header :class="{ desktop: is_desktop }" :collapse="is_desktop ? 'fade' : ''" mode="ios">
    <ion-toolbar mode="ios">
      <ion-buttons v-if="!is_desktop" slot="start">
        <ion-button color="tertiary" @click="onBack">
          <ion-icon :icon="chevronBack" />
        </ion-button>
      </ion-buttons>
      <ion-title class="text-base font-semibold text-monochrome-black">Profil</ion-title>
      <ion-buttons slot="end">
        <ion-menu-toggle v-if="is_desktop">
          <ion-button fill="outline" color="tertiary" size="large" class="m-0 !h-[50px] w-[50px]">
            <ion-icon slot="icon-only" color="tertiary" size="large" class="h-[26px] w-[26px]" :icon="closeOutline" />
          </ion-button>
        </ion-menu-toggle>
        <template v-else-if="active_segment === 'my_accaunt'">
          <ion-nav-link router-direction="forward" :component="ChangeUserDetails">
            <ion-button color="tertiary" class="pr-2 !text-xs !font-medium">Modifier</ion-button>
          </ion-nav-link>
        </template>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content v-touch:swipe.right="onBack">
    <div
      class="mx-auto flex min-h-full w-full flex-col pb-11 pt-6"
      :class="is_desktop ? 'px-8' : 'max-w-[374px] px-3 '">
      <base-segment v-if="is_agency" key="is-agency" v-model="active_segment" :options="agency_segment_options" />
      <fade-animation>
        <div v-if="active_segment === 'my_accaunt'" key="accaunt" class="flex flex-1 flex-col items-center">
          <profile-card class="mt-12" />
          <div v-if="Object.keys(user.data?.user).length" class="flex flex-col items-center">
            <ion-label color="primary" class="mt-3 text-center leading-5">
              {{ phoneFormat(user.data.user.phone) }}
            </ion-label>
            <ion-label color="primary" class="mt-3 text-center leading-5">
              {{ user.data.user.email }}
            </ion-label>
          </div>
          <div class="pt-12" :class="{ 'mt-auto': !is_desktop }">
            <ion-nav-link
              class="flex justify-center text-center"
              router-direction="forward"
              :component="ChangePassword">
              <base-link-button color="tertiary" class="text-sm font-semibold">
                <ion-icon slot="start" :icon="key" class="mr-2 h-6 w-6" />
                {{ hasPassword ? 'Modifier mon mot de passe' : 'Ajouter un mot de passe' }}
              </base-link-button>
            </ion-nav-link>

            <ion-nav-link v-if="is_desktop" router-direction="forward" :component="ChangeUserDetails">
              <base-link-button color="tertiary" class="mt-6 flex text-sm font-semibold" @click="allow_modify = true">
                <ion-icon slot="start" :icon="person" class="mr-2 h-6 w-6" />
                Modifier mes informations
              </base-link-button>
            </ion-nav-link>

            <base-link-button
              color="danger"
              class="flex text-sm font-semibold"
              :class="is_desktop ? ' mt-6' : ' mt-12'"
              @click="logoutHandler">
              <ion-icon slot="start" :icon="logOutOutline" class="mr-2 h-6 w-6" />
              Déconnexion
            </base-link-button>
          </div>
        </div>
        <div v-else key="agent" class="relative text-center">
          <ion-label
            v-if="user.companyName"
            color="primary"
            class="flex items-center justify-center leading-5"
            :class="is_desktop ? 'mt-14' : 'mt-6'">
            {{ user.companyName }}
          </ion-label>
          <div class="relative" :class="is_desktop ? 'mt-14' : 'mt-7'">
            <agents-list />
          </div>
        </div>
      </fade-animation>
    </div>
  </ion-content>
</template>

<script>
export default {
  name: 'ProfilePage',
};
</script>
<script setup>
import { computed, ref } from 'vue';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButtons,
  IonButton,
  IonLabel,
  IonNavLink,
  IonTitle,
  IonMenuToggle,
} from '@ionic/vue';
import { chevronBack, closeOutline, key, logOutOutline, person } from '@/assets/icons/Icons';
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/stores/auth/login';
import { useUserStore } from '@/stores/me';
import { useNativeStore } from '@/stores/native';
import ChangePassword from './ChangePassword.vue';
import ChangeUserDetails from './ChangeUserDetails.vue';
import { phoneFormat } from '@/composables/numberFormat';
import FadeAnimation from '@/components/shared/animations/FadeAnimation.vue';
import ProfileCard from '@/components/profile/cards/ProfileCard.vue';
import BaseSegment from '@/components/UI/BaseSegment.vue';
import AgentsList from './AgentsList.vue';
import BaseLinkButton from '../UI/BaseLinkButton.vue';

const loginStore = useLoginStore();
const user = useUserStore();
const nativeStore = useNativeStore();
const router = useRouter();

const is_desktop = computed(() => {
  return nativeStore.data.is_desktop;
});
const hasPassword = computed(() => {
  return user.data.user.has_password;
});
const is_agency = computed(() => {
  if (user.data?.role && user.data.role === 'owner') return true;
  return false;
});

const active_segment = ref('my_accaunt');
const agency_segment_options = ref([
  {
    value: 'my_accaunt',
    label: 'Mon compte',
  },
  {
    value: 'my_agency',
    label: 'Mon agence',
  },
]);

const logoutHandler = () => {
  loginStore.logout();
};

const onBack = () => {
  router.back();
};
</script>

<style scoped>
ion-item {
  --padding-start: 0;
}

ion-header.desktop:after {
  display: none;
}

ion-header.desktop ion-toolbar {
  --padding-start: 20px;
  --padding-end: 18px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-input {
  --color: var(--ion-color-primary);
  --placeholder-color: var(--ion-color-monochome-dark-init);
}
</style>
