import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig, AxiosResponse, AxiosHeaders } from 'axios';
import { Preferences } from '@capacitor/preferences';
import { useLoginStore } from '../stores/auth/login';
import { toastController } from '@ionic/vue';
import router from '../router';

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'accept-language': 'fr',
  },
});

// Define the routes to exclude from the interceptor check
const excludedPaths = [
  '/login',
  '/roots/register',
  '/forgot-password',
  '/email-verifications/verify',
  '/email-verifications/send',
  '/roots/networks',
  '/reset-password',
];

apiClient.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const storage = await Preferences.get({ key: 'token' });
  const token = storage.value;
  if (token) {
    (config.headers as AxiosHeaders).set('Authorization', 'Bearer ' + token);
  } else if (!excludedPaths.some((path) => config.url?.includes(path))) {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const error: AxiosError = {
      message: 'Token not found',
      config: config,
      code: 'TOKEN_NOT_FOUND',
      request: config,
      response: undefined,
      isAxiosError: true,
      name: 'AxiosError',
      toJSON: () => ({}),
    };
    source.cancel('Token not found');

    if (router.currentRoute.value.name != 'SignIn' && router.currentRoute.value.name != 'ResetPassword') {
      const presentToast = async () => {
        const toast = await toastController.create({
          message: 'Oups... merci de vous reconnecter',
          duration: 6000,
          position: 'top',
          mode: 'ios',
          buttons: [
            {
              text: 'Fermer',
              role: 'cancel',
            },
          ],
        });
        await toast.present();
      };
      presentToast();
      const authStore = useLoginStore();
      authStore.logout();
    }
    throw error;
  }
  return config;
});

apiClient.interceptors.response.use(
  (Response: AxiosResponse) => {
    return Response;
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default apiClient;
