import { Index } from '@/interfaces/Index';
import { defineStore } from 'pinia';

// variable naming with underscore
export const useIndexStore = defineStore('Index', {
   state: (): Index => {
      return {
         agent_modal: false,
         redirect_after_login: null,
         unread_count: 11
      };
   }
});