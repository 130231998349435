import { defineStore } from "pinia";
import { Agent, Agents } from '@/interfaces/Admin'
 
export const useAgency = defineStore('agency', { 
   state: () => {
      return {
         agents: [] as Agents
      } 
   },
   actions:{ 
      getListOfAgents(){
         return new Promise((resolve, reject) => {
            this.$axios.get('api/roots/agencies/realtors')
            .then((response) => {
               response.data?.data?.length ? this.agents = response.data.data : '';
               resolve(response);
            }) 
            .catch((err) => {
               reject(err);
            })
         })
      }, 
      deleteAgent(id: string){
         return new Promise((resolve, reject) => {
            this.$axios.delete(`api/roots/agencies/realtors/${id}`)
            .then((response) => {
               this.getListOfAgents();
               resolve(response);
            }) 
            .catch((err) => {
               reject(err);
            })
         })
      },
      addAgent(agent: Agent){
         return new Promise((resolve, reject) => {
            this.$axios.post('/api/roots/agencies/realtors', agent)
            .then((response) => {
               this.getListOfAgents();
               resolve(response);
            })
            .catch((err) => {
               reject(err); 
            })
         })
      }
   }
})