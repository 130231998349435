import { defineStore } from 'pinia';
import { Device } from '@/interfaces/Device';
import { Capacitor } from '@capacitor/core';
import { Device as CapacitorDevice } from '@capacitor/device';
import { App } from '@capacitor/app';

// variable naming with underscore
export const useDeviceStore = defineStore('Device', {
  state: (): Device => {
    return {
      token: '',
      platform: '',
      device_id: '',
      os: '',
      model: '',
      app_version: '',
      app: 'pro',
    };
  },
  actions: {
    async fillDeviceData() {
      const deviceInfo = await CapacitorDevice.getInfo();
      const deviceId = await CapacitorDevice.getId();
      if (Capacitor.isNativePlatform()) {
        const appInfo = await App.getInfo();
        this.app_version = appInfo.version + ' ' + appInfo.build;
      }

      this.platform = deviceInfo.platform;
      this.model = deviceInfo.model;
      this.os = deviceInfo.operatingSystem + ' ' + deviceInfo.osVersion;
      this.device_id = deviceId.identifier;
    },
    setDeviceToken(token: string) {
      this.token = token;
    },
    saveDevice: function () {
      return new Promise((resolve, reject) => {
        const postData = {
          token: this.token,
          platform: this.platform,
          device_id: this.device_id,
          os: this.os,
          model: this.model,
          app_version: this.app_version,
          app: this.app,
        };
        this.$axios
          .post('/api/me/devices', postData)
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
  },
});
