import { getActivePinia, Pinia, Store } from 'pinia';
import { useNativeStore } from '@/stores/native';
import { getPlatforms } from '@ionic/vue';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

export function useResetStores() {
  const nativeStore = useNativeStore();
  const pinia = getActivePinia() as ExtendedPinia;
  // if no stores return
  if (!pinia) return;
  // iterate all stores and call $reset
  pinia._s.forEach((store) => store.$reset()); 
  getPlatforms().includes('desktop') ? (nativeStore.data.is_desktop = true) : (nativeStore.data.is_desktop = false);
}
