import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import { IonicVue } from '@ionic/vue';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import axios from './utils/axios';
import Vue3TouchEvents from "vue3-touch-events";
import VueSocialSharing from 'vue-social-sharing'

// import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/font.css';
import './theme/variables.css';
import './theme/tailwind.css';
import './theme/typography.css';
import './theme/reset.css';

// Init Pinia store
const pinia = createPinia();

import 'pinia';
declare module 'pinia' {
  export interface PiniaCustomProperties {
    $axios: import('axios').AxiosInstance;
    $router: import('vue-router').Router;
  }
}

pinia.use(({ store }) => {
  store.$axios = axios;
  store.$router = markRaw(router);
});

const app = createApp(App).use(IonicVue).use(pinia).use(router).use(Vue3TouchEvents).use(VueSocialSharing);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  trackComponents: true,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [process.env.BASE_URL, process.env.VUE_APP_API_BASE_URL + '/api', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

router.isReady().then(() => {
  app.mount('#app');
});

defineCustomElements(window);
