interface PriceFormat {
  style?: string;
  currency?: string;
  minimumFractionDigits?: number;
}

export const percentFormat = (formatNumber: number, maximumFractionDigits = 2, county = 'fr-FR') => {
  if(!formatNumber) return '';
  return new Intl.NumberFormat(county, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: maximumFractionDigits }).format(formatNumber/100);
};

export const currencyFormat = (formatNumber: number, option?: PriceFormat, county = 'fr-FR') => {
  if(!formatNumber) return '';
  if (!option) {
    option = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 };
  }

  return new Intl.NumberFormat(county, option).format(formatNumber);
};

export const numberFormat = (formatNumber: number, county = 'fr-FR') => {
  return new Intl.NumberFormat(county, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(formatNumber);
};

export const phoneFormat = (formatNumber: number | string) => {
  let phone_number = formatNumber;
  typeof phone_number === 'number' ? phone_number = phone_number.toString() : '';
  const match = phone_number.replace(/(^\+33)(\d)(\d+)/, '$1 $2 $3').replace(/\B(?=(\d{2})+(?!\d))/g, " ");
  if (match) return match;
  return null;
};